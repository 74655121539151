<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Profil Sekolah</strong>
            <a-button-group class="ml-2">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Refresh Data</span>
                </template>
                <a-button @click="saveChanges" class="text-primary">
                  <a-icon type="save" /> Save Changes
                </a-button>
              </a-tooltip>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Refresh Data</span>
                </template>
                <a-button @click="getAllData(true)">
                  <a-icon type="sync" class="text-success" />
                </a-button>
              </a-tooltip>
            </a-button-group>
          </template>
          <div class="row">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label>Name</label>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-2">
              <a-input class="" v-model="input.name"></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label>Address</label>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-2">
              <a-input class="" v-model="input.address"></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label>Phone</label>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-2">
              <a-input class="" v-model="input.phone"></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label>Email</label>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-2">
              <a-input class="" v-model="input.email"></a-input>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <label>Website</label>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-2">
              <a-input class="" v-model="input.website"></a-input>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <strong>Profile</strong>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <mc-wysiwyg v-model="input.profile"></mc-wysiwyg>

              <!-- <ckeditor v-model="input.profile"></ckeditor> -->
              <!-- <Editor api-key="6ayazpij0mej96wdazqmis2xdu08sn0msyc5s91kcy6me905"
              v-model="input.profile"
              :init="{
                plugins: '',
                height: 300,
              }"></Editor> -->
              <!-- <textarea
                :value="input.profile"
                rows="20"
                style="width: 100%"
              ></textarea> -->
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <vue-markdown
                :key="componentKey"
                :html="true"
                :linkify="true"
                :toc="true"
                :source="input.profile"
                :show="true"
                :breaks="true"
                :emoji="true"
                :typographer="true"
                v-on:rendered="allRight"
                v-on:toc-rendered="tocAllRight"
                toc-id="toc"
              ></vue-markdown>
            </div> -->
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { McWysiwyg } from '@mycure/vue-wysiwyg'

// import { ckeditor } from 'ckeditor4-vue'
// import Editor from '@tinymce/tinymce-vue'
// import VueMarkdown from 'vue-markdown'
export default {
  created() {
    this.getAllData()
  },
  components: {
    McWysiwyg,

    // ckeditor: ckeditor,
    // Editor,
    // VueMarked,
    // VueMarkdown,
  },
  data() {
    return {
      input: {
        name: '',
        address: '',
        phone: '',
        email: '',
        website: '',
        profile: '',
      },
      listToc: '',
      componentKey: 0,
    }
  },
  methods: {
    moment,
    allRight: function (htmlStr) {
      // console.log('markdown is parsed !')
    },
    async getAllData() {
      var res = await lou.customUrlGet2('sekolah/profile')
      if (res) {
        this.input = this.$g.clone(res.data)
      }
    },
    async saveChanges() {
      var fd = this.$g.clone(this.input)
      var res = await lou.customUrlPost2('sekolah/profile', fd)
      if (res) {
        this.getAllData()
      }
    },
    update: debounce(function (e) {
      // console.log('e.target.value', e.target.value)
      this.input.profile = e.target.value
      this.componentKey++
    }, 200),
    tocAllRight: function (tocHtmlStr) {
      // console.log('toc is parsed :', tocHtmlStr)
      this.listToc = tocHtmlStr
    },
  },
}
</script>

<style lang="scss" scoped>
textarea,
#editor div {
  display: inline-block;
  width: 49%;
  height: 100%;
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 20px;
}

textarea {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: "Monaco", courier, monospace;
  padding: 20px;
}
code {
  color: #f66;
  background-color: silver;
  padding: 10px;
}

.wysiwyg-button {
  border: none !important;
}
</style>
